<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <router-link
      class="flex align-center sulguni mb-7 text-rocky"
      :to="{ name: 'admin' }"
    >
      <r-icon
        class="mr-2"
        icon="arrow-left"
      />
      Назад
    </router-link>
    <r-bulb
      :is-block="true"
      :is-shape="false"
      color="fargo"
      :title="!isSave ? 'Не сохранено' : ''"
      position="eluno"
      size="marina"
    >
      <template #content>
        <h1 class="ricotta">
          {{
            isEditPage && !$route.query.moderate && !isCopy
              ? title
              : 'Новый объект культуры'
          }}
        </h1>
      </template>
    </r-bulb>
    <div :key="this.$route.params.id || 0">
      <div class="grid-cont mt-8">
        <div class="edit__form">
          <r-select
            class="flex-1"
            :items="objectTypes"
            label="Тип объекта"
            v-model="selectedCategory"
            @input="isChange('selectedCategory')"
          />

          <r-input
            @input="isChange('title')"
            class="mt-6"
            :error="!title"
            label="Наименование"
            v-model="title"
          />
          <r-textarea
            @input="isChange('workHours')"
            class="mt-6 h100px"
            label="График работы"
            :rows="4"
            v-model="workHours"
          />
        </div>

        <div class="edit__map">
          <rir-map
            v-if="isShowMap"
            :center="coordinates"
            @click="onMapClick"
            collapse-btn
            @markersWasChange="markersWasChange"
          >
            <ymap-marker
              :coords="coordinates"
              :marker-id="'marker'"
              :options="{
                hideIconOnBalloonOpen: false,
                draggable: true,
                checkZoomRange:true
              }"
              :icon="$markerIcon()"
              @dragend="onDragMarker"
            />
          </rir-map>
        </div>
      </div>

      <div class="grid-cont mt-10">
        <div>
          <div class="flex">
            <div class="flex-1 mr-2">
              <r-input
                @onPressEnter="addPhone"
                label="Телефон"
                v-model="phone"
              />
              <contact-items
                v-show="phoneList.length"
                class="mt-4"
                v-model="phoneList"
              />
            </div>
            <r-button-simple
              size="larishae"
              type="primary"
              color="rocky"
              @click="addPhone"
              icon="add"
              :disabled="!phone"
            />
          </div>

          <div class="mt-6 flex align-start">
            <div class="flex-1 mr-2">
              <r-input
                label="E-mail"
                @onPressEnter="addMail"
                v-model="mail"
              />
              <contact-items
                v-show="mailList.length"
                class="mt-4"
                icon="mail"
                v-model="mailList"
              />
            </div>
            <r-button-simple
              size="larishae"
              type="primary"
              color="rocky"
              @click="addMail"
              icon="add"
              :disabled="!mail"
            />
          </div>

          <r-input
            @blur="isChange('web')"
            class="mt-6"
            label="Сайт"
            v-model="web"
          />
        </div>

        <r-input
          :error="!address"
          label="Адрес"
          v-model="address"
          @input="isChange('address')"
          @blur="searchAddress(address)"
          @onPressEnter="searchAddress(address)"
        />
      </div>

      <div class="grid-cont mt-10">
        <div>
          <h2 class="taleggio mb-6">
            Исторические сведения
          </h2>
          <r-textarea
            @input="isChange('description')"
            class="h280px"
            label="Описание"
            :rows="13"
            v-model="description"
          />
        </div>

        <div>
          <h2 class="taleggio mb-6">
            Фотогалерея
          </h2>
          <upload-file-input
            :count-file="5"
            upload-url="/uploadfile/index.php"
            :value="images"
            @input-file="file($event, 'img')"
            :accept-file="['image/png', 'image/jpeg', 'image/gif']"
            @input="uploadFile($event, 'img')"
            :key="this.$route.params.id || 0"
            sub-title="или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"
          />
          <h2 class="taleggio mt-10 mb-6">
            Аудиогид
          </h2>
          <!-- {{ audioFiles }} -->
          <div class="edit__audio">
            <upload-file-input
              :count-file="5"
              upload-url="/uploadfile/index.php"
              :value="audioFiles"
              @input-file="file($event, 'audio')"
              :accept-file="['audio/mpeg']"
              @input="uploadFile($event, 'audio')"
              sub-title="или перетяните сюда до 5 аудиофайлов в формате .MP3, общим объёмом не более 50 МБайт"
            />
          </div>
        </div>
      </div>

      <template v-if="isEditPage">
        <h2 class="taleggio mt-10">
          Автор
        </h2>
        <div class="grid-cont mt-6">
          <r-input
            label="Пользователь"
            readonly
            v-model="author"
          />
          <r-input
            label="E-mail"
            readonly
            v-model="authorEmail"
          />
        </div>
      </template>
      <div
        class="flex align-center custom mt-10 mb-60px pointer fargo-hover"
        @click="DeleteItem"
        v-if="isEditPage && approved === '1'"
      >
        <r-icon
          icon="delete"
          fill="fargo"
        />
        <div class="text-fargo ml-2">
          Удалить объект культуры
        </div>
      </div>
      <div class="flex mt-10 button_container">
        <r-button
          class="flex-1"
          @click="submit"
          :disabled="isSave || (!selectedCategory || !address || !title)"
          :title="isEditPage ? (approved === '0' ? 'Опубликовать' : 'Сохранить') : 'Добавить'"
          width="wide"
        />

        <r-button
          class="ml-6 flex-1"
          v-show="approved === '0'"
          @click="DeleteItem"
          :disabled="isLoading"
          title="Удалить"
          width="wide"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import ContactItems from '../components/ContactItems.vue';
import ModalReject from '../components/ModalReject.vue';
import RirMap from '@/components/RirMap.vue';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';
import PlaceApi from '../api/PlaceApi';
import UploadFileInput from '@/components/UploadFileInput.vue';

export default {
  components: { UploadFileInput, ContactItems, RirMap },
  data() {
    return {
      workHours: null,
      flyButton: '',
      description: null,
      images: [],
      audioFiles: [],
      selectedCategory: null,
      title: null,
      address: null,
      desc: null,
      phone: null,
      mail: null,
      web: null,
      author: 'Иванов Иван',
      authorEmail: 'ivanov@test.writer',
      phoneList: [],
      mailList: [],
      isLoading: false,
      approved: '1',
      coordinates: this.$cityCenter,
      isShowMap: false,
      initialData: [],
      isSave: true
    };
  },

  computed: {
    isEditPage() {
      return this.$route.name === 'edit';
    },

    isCopy() {
      return this.$route.name === 'copy';
    },

    objectTypes() {
      if (!this.$store.state.types?.length) return [];
      return this.$store.state.types.map((el, index) => ({
        id: el.id,
        title: el.type
      }));
    },

    currObject() {
      return this.$store.state.selectedObject;
    }
  },
  watch: {
    /* address: _.debounce(function (newVal) {
       this.searchAddress(newVal)
     }, 300), */

    currObject(val) {
      // console.log('val watch', val);
      this.title = val.name;
      this.description = val.description;
      this.address = val.address ? val.address : null;
      this.workHours = val.schedule;
      this.web = val.web ? val.web : 'null';
      this.selectedCategory = val.typeId ? String(val.typeId) : null;
      this.phoneList = val.phones;
      this.mailList = val.emails;
      this.approved = val.approved;
      this.author = val.author;
      this.authorEmail = val.authorEmail;
      this.coordinates = [val.lat, val.lng];
      this.images = val.images.map(function (el) {
        if(el?.filename) {
          return {
            icon: null,
            type: 'image/jpeg',
            filename: el.filename,
            name: el.filename, // .split('.')[0]
            url: el.url
          }
        } else {
          return {
            icon: null,
            type: 'image/jpeg',
            filename: el,
            name: el, // .split('.')[0]
            url: el
          }
        }
      });
      this.audioFiles = val.audioUrls.map(el => ({
        icon: 'music',
        filename: el.filename,
        type: 'audio/mpeg',
        name: el.filename, // .split('.')[0]
        url: el.url
      }));
    }
  },

  async activated() {
    this.resetData();
    !this.objectTypes?.length && (await this.$store.dispatch('loadTypes'));
    !this.$store.state.objects?.length && (await this.$store.dispatch('loadObjects'));
    (this.isEditPage || this.isCopy)
    && this.$store.commit('setSelectedObject', this.$route.params.id);
    this.isShowMap = true;
    if (this.isEditPage && !!this.$store.state.selectedObject) {
      this.initialData = JSON.parse(JSON.stringify(this.$store.state.selectedObject));
    }
  },

  deactivated() {
    this.isShowMap = false;
  },
  // beforeRouteEnter(to, from, next) {
  //   if (['edit', 'copy'].includes(to.name)) {
  //     next(vm => {
  //       vm.$store.dispatch('selectObject', vm.$route.params.id);
  //     });
  //   } else {
  //     next(vm => vm.resetData());
  //   }
  // },
  mounted() {
    if (!this.isEditPage) {
      this.isSave = false;
      if (this.$refs.content.offsetHeight > window.innerHeight) {
        this.flyButton = true;
      } else {
        this.flyButton = false;
      }
    }
  },
  updated() {
    if (this.isEditPage) {
      if (this.$refs.content.offsetHeight > window.innerHeight) {
        this.flyButton = true;
      } else {
        this.flyButton = false;
      }
    }
  },
  methods: {
    markersWasChange(e, map) {
      map.setCenter(this.coordinates, 15, {
        checkZoomRange: true
      });
    },
    // onClickBtn
    submit() {
      if (this.isEditPage && this.approved !== '0') {
        this.OpenSaveModal();
      } else {
        this.onClickBtn();
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.onClickBtn,
        title: this.title
      });
    },
    async DeleteItem() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$route.params.id,
        title: this.title
      });
    },
    isChange(field) {
      if (this.isEditPage) {
        if (this.initialData[field] === this[field]) {
          this.isSave = true;
        } else {
          this.isSave = false;
        }
      }
    },
    /* onMapClick(coords) {
      this.coordinates = coords;
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.coordinates[1]},${this.coordinates[0]}&format=json&lang=ru_RU&kind=house`)
        .then((result) => {
          var geo = result.data.response.GeoObjectCollection.featureMember?.[0];

          if (geo) {
            this.address = geo.GeoObject.name;
            this.isChange('address');
          }
        });
    },
    onDragMarker(e) {
      this.coordinates = e.get('target').geometry.getCoordinates();
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.coordinates[1]},${this.coordinates[0]}&format=json&lang=ru_RU&kind=house`)
        .then((result) => {
          var geo = result.data.response.GeoObjectCollection.featureMember?.[0];

          if (geo) {
            this.address = geo.GeoObject.name;
            this.isChange('address');
          }
        });
    }, */
    /* onCoordinates(item) {
      if (item) {
        axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=Волгодонск ${item.trim()}&format=json&lang=ru_RU&kind=house`)
          .then((result) => {

            var geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

            if (geo) {
              this.coordinates = geo.Point?.pos?.split(' ').reverse();
            }
          });
      }
    }, */

    onMapClick(coords) {
      this.coordinates = coords;
      this.searchAddress(coords);
    },
    onDragMarker(e) {
      /*
      this.coordinates = e.get('target').geometry._bounds[0];
      this.searchAddress(this.coordinates); */
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.coordinates = coords;
        this.searchAddress(this.coordinates);
      }
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new PlaceApi();

      const data = {};
      const isFindAddress = Array.isArray(address);
      console.log('isFindAddress', address);
      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.lat = res.lat;
        this.lng = res.lng;
        console.log('latlng', this.lat, this.lng);
        this.coordinates = [this.lat, this.lng];
      }
      this.isChange('address');
    },
    async onClickDelete() {
      // console.log('delete', this.$route.params.id);
      // await this.$refs.modal.openModal(ModalReject);
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.uid,
        title: this.title
      });
    },
    async onClickBtn() {
      this.isLoading = true;
      const item = {
        audioUrls: this.audioFiles.map(file => ({ filename: file.filename, url: file.url })),
        description: this.description,
        emails: this.mailList,
        address: this.address,
        approved: '1',
        id: this.isEditPage ?? !this.isCopy ? Number(this.$route.params.id) : -1,
        images: this.images.map(img => ({ filename: img.filename, url: img.url })),
        name: this.title,
        phones: this.phoneList,
        schedule: this.workHours,
        typeId: Number(this.selectedCategory),
        web: this.web,
        author: this.$store.state.userInfo.fio,
        authorEmail: this.$store.state.userInfo.email
      };

      if (this.coordinates?.length) {
        item.lat = this.coordinates[0];
        item.lng = this.coordinates[1];
      }

      // console.log('click add', item);
      const res = await this.$store.dispatch('addObject', {
        item,
        coordinates: this.coordinates
      }).then(result => {
        this.isLoading = false;
        this.$store.dispatch('loadObjects');
        // this.$router.push({name: 'admin'});
        const r = this.$router.resolve({
          name: 'admin'
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
      /* if (res.error === '') {

       } */
    },
    resetData() {
      if (!this.isEditPage) {
        this.isSave = false;
      } else {
        this.isSave = true;
      }
      this.selectedCategory = this.title = this.workHours = this.address = this.phone = this.mail = this.web = this.description = null;
      this.images = [];
      this.audioFiles = [];
      this.mailList = [];
      this.phoneList = [];
      //   this.hidden = '0';
    },
    addPhone() {
      this.phoneList.push(this.phone);
      this.phone = '';
      this.isChange('phoneList');
    },
    addMail() {
      this.mailList.push(this.mail);
      this.mail = '';
      this.isChange('mailList');
    },
    uploadFile(files, type) {
      const _this = this;
      if (!files?.length) {
        _this.files = [];
        return;
      }
      _this.formattedFiles(files, type);

      this.isChange('files');
    },
    file(files, type) {
      if (type === 'img') {
        this.images = [];
      } else {
        this.audioFiles = [];
      }
      this.formattedFiles(files, type);
    },
    formattedFiles(files, type) {
      const _this = this;
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.originalName, // .split('.')[0]
            filename: el.originalName,
            icon: type === 'audio' ? 'music' : false,
            url: el.url
          }));

          if (type === 'img') {
            _this.images.push(...fileList);
          } else {
            _this.audioFiles.push(...fileList);
          }
        } else if (type === 'img') {
          _this.images.push(el);
        } else {
          _this.audioFiles.push(el);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.edit {
  &__map {
    width: 100%;
    overflow: hidden;
    height: 280px;
  }
}

::v-deep .rir-file-list__details {
  padding: 6px;
}

::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.72;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}

::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}

::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

::v-deep textarea:focus {
  background: transparent !important;
}

.h100px {
  min-height: 100px !important;
}

.h280px {
  min-height: 280px !important;
}
</style>
<style>
.rocky {
  background-color: unset;
}
.fargo {
  background-color: unset;
}
.RBulb__badge {
  background-color: unset !important;
}
</style>
